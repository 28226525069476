/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// console.log('Hello World from Webpacker')

let Trix = require("trix")
require("@rails/actiontext")

window.Trix = Trix


document.addEventListener('turbolinks:load', function(){
    // auto-link in chat, when there is only one line
    $(".chat-message-row .trix-content").each(function() {
        // console.log(this);
        if (this.children.length == 1) {
          if (this.children[0].innerHTML.indexOf("<br>") < 0) {
            if (this.textContent.trim().startsWith("http")) {
              let href = this.textContent.trim();
              this.children[0].innerHTML = `<a href=${href}>${href}</a>`;
            }
          }
        }

    })


    // hr
    $(".message_content .trix-content del").each(function() {
      if (this.textContent != "----") {
        return;
      }
      $(this).before("<hr>").remove();
    });

    // Youtube
    $(".message_content .trix-content a").each(function () {
      // Exit quickly if this is the wrong type of URL
      if (this.protocol !== 'http:' && this.protocol !== 'https:') {
        return;
      }

      // Find the ID of the YouTube video
      var id, matches;
      if (this.hostname === 'youtube.com' || this.hostname === 'www.youtube.com') {
        // For URLs like https://www.youtube.com/watch?v=xLrLlu6KDss
        matches = this.search.match(/[?&]v=([^&]*)/);
        id = matches && matches[1];
      } else if (this.hostname === 'youtu.be') {
        // For URLs like https://youtu.be/xLrLlu6KDss
        id = this.pathname.substr(1);
      }

      // Check that the ID only has alphanumeric characters, to make sure that
      // we don't introduce any XSS vulnerabilities.
      var validatedID;
      if (id && id.match(/^[a-zA-Z0-9-_]*$/)) {
        validatedID = id;
      }

      // Add the embedded YouTube video, and remove the link.
      if (validatedID) {
        $(this)
          .before('<iframe width="640" height="360" src="https://www.youtube.com/embed/' + validatedID + '" frameborder="0" allowfullscreen></iframe>')
          .remove();
      }
    });

    // Vimeo
    $(".message_content .trix-content a").each(function () {
      // Exit quickly if this is the wrong type of URL
      if (this.protocol !== 'http:' && this.protocol !== 'https:') {
        return;
      }

      // Find the ID of the Vimeo video
      var id, matches;
      if (this.hostname === 'vimeo.com') {
        id = this.pathname.substr(1);

        // Add the embedded YouTube video, and remove the link.
        $(this)
          .before('<iframe src="https://player.vimeo.com/video/' + id + '?color=fad749&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>')
          .remove();
      }
    });

    // Codepen
    $(".message_content .trix-content a").each(function () {
      // Exit quickly if this is the wrong type of URL
      if (this.protocol !== 'http:' && this.protocol !== 'https:') {
        return;
      }

      // Find the ID of the Vimeo video
      var id, matches;
      if (this.hostname === 'codepen.io') {

        user_name = this.pathname.split("/pen/")[0].substr(1)
        id = this.pathname.split("/pen/")[1]

        // Add the embedded Codepen, and remove the link.
        $(this)
          .before(`<iframe height="580" style="width: 100%;" scrolling="no" src="https://codepen.io/${user_name}/embed/${id}?height=580&theme-id=light&default-tab=result" frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true">
                    See the <a href='https://codepen.io/${user_name}/pen/${id}'>Pen</a>
                    (<a href='https://codepen.io/${user_name}'>@${user_name}</a>) on <a href='https://codepen.io'>CodePen</a>.
                  </iframe>`)
          .remove();
      }
    });

    // Slides.com
    $(".message_content .trix-content a").each(function () {
      // Exit quickly if this is the wrong type of URL
      if (this.protocol !== 'http:' && this.protocol !== 'https:') {
        return;
      }

      // Find the ID of the Vimeo video
      var id, matches;
      if (this.hostname === 'slides.com') {

        path = this.href.split("?")[0]
        query = this.href.split("?")[1] || ""

        // Add the embedded slide, and remove the link.
        $(this)
          .before(`<iframe src="${path}/embed?${query}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`)
          .remove();
      }
    });

    // MakClass.com/votes
    $(".message_content .trix-content a").each(function () {
      // Exit quickly if this is the wrong type of URL
      if (this.protocol !== 'http:' && this.protocol !== 'https:') {
        return;
      }

      // Find the ID of the Vimeo video
      var id, matches;
      if (this.hostname === 'makclass.com') {
          // alert(this.pathname.substring(0,3))
        if (this.pathname.substring(0,3) == "/v/") {
          id = this.pathname.split("/")[2] || ""

          // Add the embedded slide, and remove the link.
          $(this)
            .before(`<iframe src="https://makclass.com/v/${id}" width="640" height="650" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`)
            .remove();
        }

      }
    });
});